import { StateError } from '..';
import * as ConnectionsActions from './connections.actions';
import {
  IConnection,
  IConnectionWithScopedCouplings,
  IConsentForCoupling,
  IDataConnectInboundAuth,
  IDataConnectInboundEquipmentList, SortedConnections,
} from '@app/shared/models/connections.model';

export interface State {
  connection: IConnectionWithScopedCouplings | null;
  connections: SortedConnections;
  partnerImages:  Map<string, string>;
  partnerImage:  { id: string; logoUrl: string } | null;
  connectionPending: boolean;
  listPending: boolean;
  imagePending: boolean;
  imagelistPending: boolean;
  postPending: boolean;
  deletePending: boolean;
  error: StateError;
  dataConnectInboundConnectionAuthPending: boolean;
  dataConnectInboundAuth: IDataConnectInboundAuth | null;
  dataConnectInboundEquipmentListPending: boolean;
  dataConnectInboundEquipmentList: IDataConnectInboundEquipmentList | null;
  dataConnectPutContainerPending: boolean;
  dataConnectDeleteCouplingPending: boolean;
  dataConnectCouplingCreated: boolean;
  dataConnectCouplingIdUpdated: string | null;
}

export const initialState: State = {
  connection: null,
  connections: { inactive: [], active: [] },
  partnerImages: new Map<string, string>(),
  partnerImage: null,
  listPending: false,
  imagePending: false,
  connectionPending: false,
  imagelistPending: false,
  postPending: false,
  deletePending: false,
  error: null,
  dataConnectInboundConnectionAuthPending: false,
  dataConnectInboundAuth: null,
  dataConnectInboundEquipmentListPending: false,
  dataConnectInboundEquipmentList: null,
  dataConnectPutContainerPending: false,
  dataConnectDeleteCouplingPending: false,
  dataConnectCouplingCreated: false,
  dataConnectCouplingIdUpdated: null,
};

export function reducer(state = initialState, action: ConnectionsActions.Actions): State {
  switch (action.type) {
    case ConnectionsActions.CONNECTIONS: {
      return {
        ...state,
        listPending: true,
        imagelistPending: true,
      };
    }

    case ConnectionsActions.CONNECTIONS_SUCCESS: {
      return {
        ...state,
        connections: action.payload,
      };
    }

    case ConnectionsActions.CONNECTIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.CONNECTIONS_COMPLETE: {
      return {
        ...state,
        listPending: false,
      };
    }
    case ConnectionsActions.CONNECTION: {
      return {
        ...state,
        connectionPending: true,
        imagePending: true,
      };
    }

    case ConnectionsActions.CONNECTION_SUCCESS: {
      return {
        ...state,
        connection: action.payload,
      };
    }

    case ConnectionsActions.CONNECTION_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.CONNECTION_COMPLETE: {
      return {
        ...state,
        connectionPending: false,
      };
    }

    case ConnectionsActions.CONNECTIONS_PARTNER_IMAGES_LOADED_SUCCESS: {
      return {
        ...state,
        partnerImages: action.payload,
      };
    }

    case ConnectionsActions.CONNECTIONS_PARTNER_IMAGES_LOADED_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.CONNECTIONS_PARTNER_IMAGES_LOADED_COMPLETE: {
      return {
        ...state,
        imagelistPending: false,
      };
    }

    case ConnectionsActions.CONNECTION_PARTNER_IMAGE_LOADED_SUCCESS: {
      return {
        ...state,
        partnerImage: action.payload,
      };
    }

    case ConnectionsActions.CONNECTION_PARTNER_IMAGE_LOADED_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.CONNECTION_PARTNER_IMAGE_LOADED_COMPLETE: {
      return {
        ...state,
        imagePending: false,
      };
    }

    case ConnectionsActions.DATACONNECT_INBOUND_COUPLING_AUTH: {
      return {
        ...state,
        dataConnectInboundConnectionAuthPending: true,
      };
    }

    case ConnectionsActions.DATACONNECT_INBOUND_COUPLING_AUTH_SUCCESS: {
      return {
        ...state,
        dataConnectInboundAuth: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_INBOUND_COUPLING_AUTH_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_INBOUND_COUPLING_AUTH_COMPLETE: {
      return {
        ...state,
        dataConnectInboundConnectionAuthPending: false,
      };
    }

    case ConnectionsActions.DATACONNECT_FETCH_EQUIPMENT: {
      return {
        ...state,
        dataConnectInboundEquipmentListPending: true,
      };
    }

    case ConnectionsActions.DATACONNECT_FETCH_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        dataConnectInboundEquipmentList: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_FETCH_EQUIPMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_FETCH_EQUIPMENT_COMPLETE: {
      return {
        ...state,
        dataConnectInboundEquipmentListPending: false,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_CREATE: {
      return {
        ...state,
        dataConnectPutContainerPending: true,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_CREATE_SUCCESS: {
      return {
        ...state,
        dataConnectCouplingCreated: true,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_CREATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_CREATE_COMPLETE: {
      return {
        ...state,
        dataConnectPutContainerPending: false,
        dataConnectInboundAuth: null,
        dataConnectCouplingCreated: false,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_UPDATE: {
      return {
        ...state,
        dataConnectPutContainerPending: true,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_UPDATE_SUCCESS: {
      return {
        ...state,
        dataConnectCouplingIdUpdated: action.payload.couplingId,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_UPDATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_PUT_CONTAINER_UPDATE_COMPLETE: {
      return {
        ...state,
        dataConnectPutContainerPending: false,
        dataConnectCouplingIdUpdated: null,
      };
    }

    case ConnectionsActions.DATACONNECT_DELETE_COUPLING: {
      return {
        ...state,
        dataConnectDeleteCouplingPending: true,
      };
    }

    case ConnectionsActions.DATACONNECT_DELETE_COUPLING_SUCCESS: {
      return {
        ...state,
      };
    }

    case ConnectionsActions.DATACONNECT_DELETE_COUPLING_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ConnectionsActions.DATACONNECT_DELETE_COUPLING_COMPLETE: {
      return {
        ...state,
        dataConnectDeleteCouplingPending: false,
        dataConnectInboundAuth: null,
      };
    }

    case ConnectionsActions.RESET_CONNECTIONS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
export const getError = (state: State) => state.error;
export const getConnections = (state: State) => state.connections;
export const getConnection = (state: State) => state.connection;
export const getPartnerImages = (state: State) => state.partnerImages;
export const getPartnerImage = (state: State) => state.partnerImage;
export const getPartnerImagePending = (state: State) => state.imagePending;
export const getDataConnectInboundAuth = (state: State) => state.dataConnectInboundAuth;
export const getDataConnectInboundEquipmentList = (state: State) => state.dataConnectInboundEquipmentList;
export const getDataConnectInboundEquipmentListPending = (state: State) => state.dataConnectInboundEquipmentListPending;
export const getDataConnectPutContainerPending = (state: State) => state.dataConnectPutContainerPending;
export const getDataConnectDeleteCouplingPending = (state: State) => state.dataConnectDeleteCouplingPending;
export const getDataConnectCouplingCreated = (state: State) => state.dataConnectCouplingCreated;
export const getDataConnectCouplingIdUpdated = (state: State) => state.dataConnectCouplingIdUpdated;
